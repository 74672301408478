<template>
  
  <div class="game_info" v-if="datas != null">
    <b-row>
      <b-col class="ta-center" v-if="datas.reserved == 0">
        <br><b-img class="team-img" :src="makeTeamImgLarge(datas.home_img_id, 1)"></b-img><br><br><span class="mt-0">{{ datas.home_ko }}</span>
      </b-col>
      <b-col class="ta-center" v-else>
        <br><b-img class="team-img" :src="makeTeamImgLarge(datas.away_img_id, 1)"></b-img><br><br><span class="mt-0">{{ datas.away_ko }}</span>
      </b-col>

      <b-col class="ta-center game_info_center">
        <b-row>
          <b-col cols="12" class="p-0 m-0">
            <div><b-img class="country-img-large" :src=makeLeagueImg(datas)></b-img></div>
            <div>{{ (datas.league_code && datas.league_code != undefined)?datas.league_code:datas.league }}</div>
            <div>{{ getYear(datas.time) }}-{{ getDay(datas.time) }} &nbsp; {{ getTime(datas.time) }}</div>
          </b-col>

          <b-col cols="12" class="d-none d-md-block">
            <b-badge variant="light-primary">VS</b-badge>
          </b-col>
          <b-col cols="12" class="d-blcok d-md-none mt-1">
            <b-button v-on:click="GoBack()" variant="primary" size="sm"><feather-icon icon="ChevronsLeftIcon"></feather-icon>BACK</b-button>
          </b-col>
        </b-row>
      </b-col>

      <b-col class="ta-center">
        <b-col class="ta-center" v-if="datas.reserved == 0">
          <br><b-img class="team-img" :src="makeTeamImgLarge(datas.away_img_id, 2)"></b-img><br><br><span class="mt-0">{{ datas.away_ko }}</span>
        </b-col>
        <b-col class="ta-center" v-else>
          <br><b-img class="team-img" :src="makeTeamImgLarge(datas.home_img_id, 2)"></b-img><br><br><span class="mt-0">{{ datas.home_ko }}</span>
        </b-col>
      </b-col>
    </b-row>
  </div>

  <div class="game_info ta-center py-1" v-else>
    <div class="mb-0 no-game-icon">
      <feather-icon icon="XOctagonIcon" size="32"/>
    </div>
    <div class="no-game-info">
      배당 정보가 존재하지 않습니다
    </div>
  </div>
  
</template>

<script>
import { BCardText, BRow, BCol, BImg, BBadge, BButton, BAvatar } from 'bootstrap-vue'
import EventBus from '../../event-bus';
import { getYear, getDay, getTime, makeLeagueImg, makeTeamImgLarge } from '@core/utils/utils'


export default {
  components: {
    BCardText,
    BRow,
    BCol,
    BImg,
    BBadge,
    BButton,
    BAvatar,
  },
  data() {
    return {
      datas:[],
    }
  },
  created () {
    EventBus.$on('game_info', (obj)=>{
      this.datas = obj;
    });
  },
  methods:{
    getYear,
    getDay,
    getTime,
    makeLeagueImg,
    makeTeamImgLarge,

    GoBack(){
      EventBus.$emit('toggle_view');
    }
  }
}
</script>

<style>
.game_info {    
  background-color: #283046;
  border: 1px solid #111;
  padding:10px;
  margin-bottom:5px;
  color:white !important;
}
.badge { 
  padding:0.5rem 1rem;
  font-size:1rem;
  margin-top:4px;
}
.game_info_center { 
  line-height:20px;
}
.no-game-info {
  color: #b4b7bd;
  background-color: #171d2e;
  padding: 0.5rem 3rem;
  border-radius: calc(0.357rem * 4);
  cursor: pointer;
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12) !important;
  display:inline-block;
}
.no-game-icon{
  color: #b4b7bd;
  border-radius: 50%;
  padding: 1rem;
  box-shadow: 0 4px 8px 0 rgba(34, 41, 47, 0.12) !important;
  display:block;
}
</style>