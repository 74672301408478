<template>
  
  <b-row>

    <b-col md="12" sm="12" lg="6" xl="5" class="p-0 m-0"  v-if="view_mode === 'prematch' || client_width > 991">
    <vue-perfect-scrollbar 
          ref="Prematch"
          :settings="perfectScrollbarSettings"
          class="upcoming-scroll-area"
          :always-show-scrollbar="true"
          v-bind:style="{ height: scroll_height + 'px' }"
          @ps-scroll-y="onScroll"
        >
      <GameLeft/>
    </vue-perfect-scrollbar>
    </b-col>
    <b-col v-else class="d-none">
    <vue-perfect-scrollbar 
          ref="Prematch"
          :settings="perfectScrollbarSettings"
          class="upcoming-scroll-area"
          :always-show-scrollbar="true"
          v-bind:style="{ height: scroll_height + 'px' }"
          @ps-scroll-y="onScroll"
        >
      <GameLeft/>
    </vue-perfect-scrollbar>
    </b-col>

    <b-col lg="6" xl="5" class="p-0 m-0 d-lg-block" v-if="view_mode === 'odds' || client_width > 991">
    <vue-perfect-scrollbar
          ref="Odds"
          :settings="perfectScrollbarSettings"
          class="detail-scroll-area"
          :always-show-scrollbar="true"
          v-bind:style="{ height: scroll_height + 'px' }"
        >
      <GameRight/>
    </vue-perfect-scrollbar>
    </b-col>
    <b-col v-else class="d-none">
    <vue-perfect-scrollbar
          ref="Odds"
          :settings="perfectScrollbarSettings"
          class="detail-scroll-area"
          :always-show-scrollbar="true"
          v-bind:style="{ height: scroll_height + 'px' }"
        >
      <GameRight/>
    </vue-perfect-scrollbar>
    </b-col>

    <b-col xl="2" class="px-0 m-0 d-none d-xl-block game-cart-frame">
    </b-col>

    <app-customizer
      v-if="showCustomizer"
      slot="customizer"
      class="d-block d-xl-none"
    />

  </b-row>

</template>

<script>
import GameLeft from './GameLeft.vue'
import GameRight from './GameRight.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BRow, BCol, BAlert} from 'bootstrap-vue'
import EventBus from '../../event-bus';
import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'

export default {
  data(){
    return {
      //timer : null,
      scroll_height : document.documentElement.clientHeight - 10,
      client_width : document.documentElement.clientWidth,
      view_mode : 'prematch',
      showCustomizer: $themeConfig.layout.customizer,
    }
  },
  components: {
    BRow, BCol, BAlert,
    GameLeft, GameRight, 
    EventBus, VuePerfectScrollbar, AppCustomizer
  },
  created(){
    EventBus.$on('upcoming-scroll-top', ()=> {
      let container = document.querySelector('.upcoming-scroll-area');
      container.scrollTop = 0;
      this.$forceUpdate();
    });
    EventBus.$on('toggle_view', (obj)=> {
      if( this.view_mode == 'prematch' ){
        this.view_mode = 'odds';
      }else{
        this.view_mode = 'prematch';
      }
      EventBus.$emit('game_view', obj);
    });
  },
  setup(){
    const perfectScrollbarSettings = {
      suppressScrollX : true,
      scrollingThreshold: 100000,
      wheelSpeed:1,
      swipeEasing:true,
    }
    return{
      perfectScrollbarSettings,
    }
  },
  updated() {
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    setInterval(this.checkAuth, 90 * 1000 );
  },
  destroyed() {
    window.removeEventListener('resize', this.onResize);
  },
  computed: {
  },
  methods: {
    onResize() {
      this.scroll_height = document.documentElement.clientHeight - 90;
      this.client_width =  document.documentElement.clientWidth;
    },
    onScroll(event) {
      if( this.$refs.Prematch.ps.scrollbarYTop + this.$refs.Prematch.ps.scrollbarYHeight > this.$refs.Prematch.ps.containerHeight - 100) 
      {
        EventBus.$emit('next_page');
      }
    }
  }
}
</script>

<style>
.upcoming-scroll-area {width:100%;}
.detail-scroll-area {width:100%;}
</style>
